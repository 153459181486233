import React from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Bio from '../components/Bio'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { rhythm } from '../utils/typography'

import icon from '../../static/favicon.ico'

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Helmet
          link={[
            {
              rel: 'shortcut icon',
              type: 'image/png',
              href: `${icon}`,
            },
          ]}
        >
          <title>Consulting - Alex Kondov</title>
          <meta
            property="og:image"
            content={`https://alexkondov.com${this.props.data.cover.childImageSharp.fluid.src}`}
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta
            property="og:title"
            content="Alex Kondov's Personal Blog"
          />
          <meta
            property="og:description"
            content="Thoughts on programming, writing and the things in between."
          />
          <meta property="og:url" content="https://alexkondov.com" />
          <meta
            name="twitter:card"
            content="summary_large_image"
          ></meta>
          <meta property="og:type" content="website" />
          <meta property="og:locale" content="en_US" />
        </Helmet>
        <h2 className="section-title">Consulting</h2>
        <p>
          I've spent the last 10 years building software of various
          scale and complexity, and the majority of that time I was
          working with React.
        </p>
        <p>
          I've built React applications for early stage Sillicon
          Valley startups, financial companies like SumUp, and media
          corps like The Financial Times. Every business is different
          and has different technological needs, but both startups and
          corporations need well-built software to move fast in
          today's dynamic market.
        </p>
        <p>I can help you with that.</p>
        <h3 className="consulting-section">
          React Architecture and Direction
        </h3>
        <p>
          You're starting a new project and you need someone to get
          things up and running? You're not sure what framework, state
          management library or styling solution to use? Need someone
          to do code reviews for your team?
        </p>
        <p>I can help.</p>
        <h3 className="consulting-section">React Training</h3>
        <p>
          You have a team that needs to level up their React skills?
          You want to introduce React to your team and you need
          someone to guide you through the process?
        </p>
        <p>
          I can run a workshop for you and your team, tailored to your
          needs.
        </p>
        <h3 className="consulting-section">React Audit</h3>
        <p>
          You inherited a codebase that's hard to work with and you're
          not sure what to do?
        </p>
        <p>
          I can audit your application for code smells, potential
          problems, and prepare a plan for improvement.
        </p>
        <a
          href="mailto:alexkondov38@gmail.com"
          className="consulting-button"
        >
          Message Me
        </a>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    cover: file(absolutePath: { regex: "/cover.png/" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
